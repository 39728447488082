<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <v-alert
      v-if="alert.visable"
      color="primary"
      dark
      icon="fa-regular fa-hexagon-exclamation"
      border="left"
      prominent
    >
      {{ alert.description }}
    </v-alert>


    <view-intro class="text-start" heading="Alle bestellingen"/>

    <material-card
      class="mt-10 elevation-1"
      icon="fa-regular fa-boxes-stacked"
      icon-small
      title="Open bestellingen"
      color="accent"
    >
      <v-data-table
        @click:row="goToDetail"
        :loading="loading"
        loading-text="Laden even geduld aub"
        :headers="headers"
        :items="orders.open"
        :items-per-page="-1"
      ></v-data-table>
    </material-card>
    <material-card
      class="mt-10 elevation-1"
      icon="fa-regular fa-circle-check"
      icon-small
      title="Afgelevert bestellingen"
      color="accent"
    >
      <v-data-table
        @click:row="goToDetail"
        :loading="loading"
        loading-text="Laden even geduld aub"
        :headers="headers"
        :items="orders.closed"
        :items-per-page="10"
      ></v-data-table>
    </material-card>
    <material-card
      class="mt-10 elevation-1"
      icon="fa-regular fa-circle-xmark"
      icon-small
      title="Onderbroken bestellingen"
      color="accent"
    >
      <v-data-table
        @click:row="goToDetail"
        :loading="loading"
        loading-text="Laden even geduld aub"
        :headers="headers"
        :items="orders.interrupted"
        :items-per-page="10"
      ></v-data-table>
    </material-card>
  </v-container>
</template>

<script>
  export default {
    name: 'RegularTablesView',
    data () {

      try {
        this.$auth.getOpenOrderGroups().then(orderGroups => {
          orderGroups = orderGroups.sort((a, b) => new Date(b.date.seconds * 1000) - new Date(a.date.seconds * 1000))
          this.orders.open = this.$filter.filterOrderGroups(orderGroups)
          this.$auth.getClosedOrderGroups().then(orderGroups => {
            orderGroups = orderGroups.sort((a, b) => new Date(b.date.seconds * 1000) - new Date(a.date.seconds * 1000))
            this.orders.closed = this.$filter.filterOrderGroups(orderGroups)
            this.$auth.getInterruptedOrderGroups().then(orderGroups => {
              orderGroups = orderGroups.sort((a, b) => new Date(b.date.seconds * 1000) - new Date(a.date.seconds * 1000))
              this.orders.interrupted = this.$filter.filterOrderGroups(orderGroups)
            }).finally(() => {
              this.loading = false
            })
          })
        })
      } catch (err) {
        this.alert.visible = true
        this.alert.description = `Er is iets mis gegaan error: ${err} Neem graag contact met beheerder`
      }

      return {
        headers: [
          {
            text: 'Orders',
            value: 'orders',
          },
          {
            text: 'Code',
            value: 'code',
          },
          {
            text: 'Datum',
            value: 'date',
          },
          {
            text: 'Totaal prijs',
            sortable: false,
            value: 'price',
          }
        ],
        orders: {
          open: [],
          closed: [],
          interrupted: [],
        },
        loading: true,
        alert: {
          visible: false,
          description: '',
        },
      }
    },
    methods: {
      goToDetail (order) {
        const groupId = order.groupId
        this.$router.push(`/orders/${groupId}`)
      },
    },
  }
</script>
